html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Keep this to prevent horizontal scroll */
  overflow-y: auto;   /* Enable vertical scrolling */
}

.App {
  position: relative;
  min-height: 100vh;  
}

.scroll {
  position: relative;
  z-index: 1;
  min-height: 100vh;
}

.hellix-font {
  font-family: Hellix !important;
}
ul li {
  list-style-type: disc;
  list-style-position: inside;
}

ul li::marker {
  color: gray;
}

/* Custom styling */

.accordion-header {
  background-color: #eeeeee;
}

.accordion-content {
  padding: 10px;
  border-top: 1px solid #ddd;
}
@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-Regular.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Hellix";
  src: url("./fonts/Hellix-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

