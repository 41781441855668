@tailwind base;
@tailwind components;
@tailwind utilities;

.banner-bg{
     background-image: url("./images/home/banner-bg.jpg");
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
}
.banner-bg-gradient{
    background-image: url("./images/home/gradient.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-image {
    background-image: linear-gradient(to right, #166772, #16677259),
    url('./images/individual/banner-images.png');
 }

 .bg-empspns {
    background-image: linear-gradient(to right, #166772, #16677259),
    url('./images/individual/EmployerSponsorNomination.jpg');
 }

 .text-with-border {
    font-weight: 700;
    text-shadow: 
      1px 1px 0px white, 
      -1px -1.5px 1px white, 
      0px -1px 1px white, 
      -1px 1px 0px white;
}

.whatsapp-overlay {
  position: fixed;
  right: 20px;
  z-index: 1000;
  bottom: 20px;
  transition: bottom 0.2s linear;
}

.whatsapp-button {
  display: inline-block;
  background: #25d366;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: transform 0.2s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

.whatsapp-icon {
  width: 30px;
  height: 30px;
}

.smooth-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.smooth-content {
  width: 100%;
  min-height: 100vh;
  position: relative;
  will-change: transform;
}

/* Ensure proper stacking of fixed elements */
.whatsapp-overlay {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  transition: bottom 0.3s ease;
}

.whatsapp-button {
  display: block;
  transition: transform 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

.whatsapp-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Make sure content elements are properly positioned */
.App {
  position: relative;
}

/* Optional: Add data-speed attribute to elements for parallax effects */
/* Example:
[data-speed="0.8"] {
  transition: none !important;
}
*/
